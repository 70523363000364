.newsletter {
    padding: 80px 0;
    background: #0022a7;
    }
    
    .newsletter .content {
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2; }
    .newsletter .content h2 {
    color: #ffffff;
    margin-bottom: 40px; }
    .newsletter .content .form-control {
    height: 50px;
    border-color: #ffffff;
    border-radius:0;
    }
    .newsletter .content.form-control:focus {
    box-shadow: none;
    border: 2px solid #243c4f;
    }
    .newsletter .content .btn {
    min-height: 50px; 
    border-radius:0;
    background: #243c4f;
    color: #fff;
    font-weight:600;
    }