
#social .social-connect a {
    color: #fff;
}

#social .social-connect a:hover {
    color: #E0E0E0;
}

#social .social-connect .line {
    background-color: lightgrey;
    width: 50px;
    margin: 12px 7px 25px 7px;
    border-radius: 50px;
}

#social .social-connect .box {
    width: 30%;
    padding: 30px 30px 35px 30px;
    margin-bottom: 20px;
    height: auto;
    position: relative;
}

@media screen and (max-width: 992px) {
    #social .social-connect .box {
        width: 48%;
    }
    #social .social-connect .btn-pink {
        width: 150px !important;
    }
}

@media screen and (max-width: 768px) {
    #social .social-connect .box {
        width: 80%;
    }
}

#social .social-connect .box1 {
    background-color: #E91E63;
    color: #fff;
}

#social .social-connect .box2 {
    background-color: #5C6BC0;
    color: #fff;
}

#social .social-connect .box3 {
    background-color: #42A5F5;
    color: #fff;
}

#social .social-connect .fa-lightbulb-o {
    color: #E91E63;
    background-color: #fff;
    border-radius: 50%;
    font-size: 40px;
    padding: 20px 30px;
}

#social .social-connect .btn-pink {
    border: 1px solid #fff;
    border-radius: 25px;
    color: #fff;
    background-color: #E91E63;
    height: 50px;
    width: 180px;
}

#social .social-connect .btn-pink:hover {
    color: #E91E63;
    background-color: #fff;
}

#social .social-connect .btn-pink:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}

#social .social-connect .sm-text {
    font-size: 14px;
}

#social .social-connect .bottom {
    bottom: 20px;
    position: absolute;
    width: 150px;
}

#social .social-connect .mt-large {
    margin-top: 120px;
}

#social .social-connect .title-btm {
    font-weight: bold;
}

#social .social-connect select {
    border: 1px solid lightgrey;
}

#social .social-connect .red-text {
    color: red;
    cursor: pointer;
}

#social .social-connect .red-text:hover {
    text-decoration: underline;
}

#social .social-connect .footer-line {
    border-top: 1px solid lightgrey;
}