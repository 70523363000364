#aboutus .section-head {
    margin-bottom: 60px;
  }
  #aboutus .section-head h4 {
    position: relative;
    padding:0;
    
    line-height: 1;
    letter-spacing:0.3px;
    font-size: 34px;
    font-weight: 700;  
    text-align:center;
    text-transform:none;
    margin-bottom:30px;
  }
  #aboutus  .section-head h4:before {
    content: '';
    width: 60px;
    height: 3px;
   
    position: absolute;
    left: 0px;
    bottom: -10px;
    right:0;  
    margin:0 auto;
  }
  #aboutus  .section-head h4 span {
    font-weight: 700;
    padding-bottom: 5px;
    color:#2f2f2f
  }
  #aboutus p.service_text{
    
    font-size:16px;
    line-height:28px;
    text-align:center;    
  }
  #aboutus .section-head p, p.awesome_line{
    
    font-size:16px;
    line-height:28px;
    text-align:center;  
  }
  
  #aboutus .extra-text {
      font-size:34px;
      font-weight: 700;
      margin-bottom: 25px;
      position:relative;
      text-transform: none;
  }
  #aboutus .extra-text::before {
      content: '';
      width: 60px;
      height: 3px;
      background: #f91942;
      position: absolute;
      left: 0px;
      bottom: -10px;
      right: 0;
      margin: 0 auto;
  }
  #aboutus .extra-text span {
      font-weight: 700;
      
  }
  #aboutus .item {
      background: #fff;
      text-align: center;
      padding: 30px 25px;
      -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
      box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
      border-radius: 20px;
      border:5px solid rgba(0, 0, 0, 0.07);
      margin-bottom: 30px;
      -webkit-transition: all .5s ease 0;
      transition: all .5s ease 0;
      transition: all 0.5s ease 0s;
  }
 
  #aboutus .item:hover .item, .item:hover span.icon{
     
      border-radius:10px;
      -webkit-transition: all .5s ease 0;
      transition: all .5s ease 0;
      transition: all 0.5s ease 0s;
  }
  #aboutus  .item:hover h6, .item:hover p{
      
      -webkit-transition: all .5s ease 0;
      transition: all .5s ease 0;
      transition: all 0.5s ease 0s;
  }
  #aboutus .item .icon {
      font-size: 40px;
      margin-bottom:25px;
      color: #f91942;   
      width: 90px;
      height: 90px;
      line-height: 96px;
      border-radius: 50px;
  }
  #aboutus .item .feature_box_col_one{
      background:rgba(247, 198, 5, 0.20);
      color:#f91942
  }
  #aboutus .item .feature_box_col_two{
      background:rgba(255, 77, 28, 0.15);
      color:#f91942
  }
  #aboutus .item .feature_box_col_three{
      background:rgba(0, 147, 38, 0.15);
      color:#f91942
  }
  #aboutus .item .feature_box_col_four{
      background:rgba(0, 108, 255, 0.15);
      color:#f91942
  }
  #aboutus  .item .feature_box_col_five{
      background:rgba(146, 39, 255, 0.15);
      color:#f91942
  }
  #aboutus .item .feature_box_col_six{
      background:rgba(23, 39, 246, 0.15);
      color:#f91942
  }
  #aboutus .item p{
      font-size:15px;
      line-height:26px;
  }
  #aboutus .item h6 {
      margin-bottom:20px;
      color:#2f2f2f;
  }
  #aboutus .mission p {
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 28px;
      font-weight: 500;
  }
  #aboutus .mission i {
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #f91942;
      border-radius: 50%;
      color: #fff;
      font-size: 25px;
  }
  #aboutus .mission .small-text {
      margin-left: 10px;
      font-size: 13px;
      color: #666;
  }
  #aboutus .skills {
      padding-top:0px;
  }
  #aboutus .skills .prog-item {
      margin-bottom: 25px;
  }
  #aboutus .skills .prog-item:last-child {
      margin-bottom: 0;
  }
  #aboutus .skills .prog-item p {
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 10px;
  }
  #aboutus .skills .prog-item .skills-progress {
      width: 100%;
      height: 10px;
      background: #e0e0e0;
      border-radius:20px;
      position: relative;
  }
  #aboutus .skills .prog-item .skills-progress span {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: #f91942;
      width: 10%;
      border-radius: 10px;
      -webkit-transition: all 1s;
      transition: all 1s;
  }
  #aboutus .skills .prog-item .skills-progress span:after {
      content: attr(data-value);
      position: absolute;
      top: -5px;
      right: 0;
      font-size: 10px;
      font-weight:600;    
      color: #fff;
      background:rgba(0, 0, 0, 0.9);
      padding: 3px 7px;
      border-radius: 30px;
  }