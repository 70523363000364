 .hero-section .headline {
    font-size: 2.0rem;
    font-weight: bold
}

#hero .hero-section .subheadline {
    font-size: 1.25rem
}
 .hero-section {
    background-color: #0022a7;
}

#hero .hero-section li {
    color: #fff;
    font-size: 25px;
    list-style:none !important;
    line-height:30px !important;
  }
  #hero .hero-section p {
    color: #fff;
    font-size: 20px;
    list-style:none !important;
    line-height:30px !important;
  }




  #hero .hero-quotes .source-profile {
    width: 60px;
    height: 60px
}




#hero .book-cover-holder {
    position: relative
}



@media (max-width: 575.98px) {
    #hero .hero-quotes .quote:after {
        left: 50%;
        margin-left: -10px
    }}


@media (max-width: 991.98px) {
    #hero .section-heading {
        font-size: 1.75rem
    }

    #hero .section-intro {
        font-size: 1.125rem
    }

    #hero .hero-section .headline {
        font-size: 2rem;
        font-weight: bold
    }
    #hero .book-cover-holder .book-cover {
    display: none;
    }
    #hero .hero-section .subheadline {
        font-size: 1.125rem
    }
    

   }