
/* Call To Action Section
--------------------------------*/
#call-to-ac {
    background:  #0022a7;
    background-size: cover;
    padding: 40px 0;
  }
  
  #call-to-ac .cta-title {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  #call-to-ac .cta-text {
    color: #fff;
    font-size: 18px;
    line-height:30px !important;
  }
  #call-to-ac p {
    color: #fff;
    font-size: 20px;
    list-style:none !important;
    line-height:30px !important;
  }
  
  
  [class^="icon"] {
    position: relative;
    bottom: 9px;
    fill: #fff;
  }
  
  @media (min-width: 769px) {
    #call-to-ac .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    
  }
  
  #call-to-ac .cta-btn {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
 
    letter-spacing: 1px;
   
    padding: 8px 26px;
    border-radius: 3px;
    transition: 0.5s;
    margin: 10px;
    border: 3px solid #fff;
    color: #fff;
  }
  
  #call-to-ac .cta-btn:hover {
    background: #FF0000;
    border: 3px solid #fff;
  }