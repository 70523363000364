

body {
    
    background-color: #fff !important;
    font-family: "Montserrat", sans-serif;
    color: #000 !important;
    overflow-x: hidden !important;

}

.footer {
    height: 100px;
    width: 100px; 
}

#footer .footer .card-0{
    overflow-x: hidden !important;
    padding:auto 0px auto 0px !important ; 
    background-color: #007bff !important;
    color: #FFFFFF !important;
    border-radius:0;
    margin: 0 !important;
   /* background: url('https://i.imgur.com/4uwpRtg.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right calc(70px + (120 - 70) * ((100vw - 320px) / (1600 - 320))) bottom !important*/    
}

#footer .footer h5{
    font-size: calc(16px + (21 - 16) * ((100vw - 360px) / (1600 - 360))) !important;

}

#footer .footer h6{
    font-size: calc(13px + (15 - 13) * ((100vw - 360px) / (1600 - 360))) !important;
    color:  #3f3d3d !important;
    margin-left:5px;
}
#footer .footer .fa{
    cursor: pointer;
    font-size: 21px ;
    margin: 5px 10px 5px 10px !important;
}
#footer .footer button:focus {
     -moz-box-shadow: none !important;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
     outline-width: 0;
 }

 .footer li{
    margin-top: 8px ;
    margin-bottom: 8px ;
}
#footer .footer input{
    border-radius:2px !important;
    background: transparent !important;
    color: #FFFFFF !important;


}

#footer .footer input:focus {
     -moz-box-shadow: none !important;
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
     outline-width: 0;
     border-color: #FFFFFF !important;
 }

 /*.footer img{
    vertical-align: middle !important;
    width: 100%;
    width: calc(30px + 6 * ((100vw - 320px) / 680)) !important;
 }
*/
#footer .footer li:first-child {
    font-size:  20px !important;
    font-weight: bold;
}

#footer .footer small{
    font-size: calc(12px + (14 - 12) * ((100vw - 360px) / (1600 - 360))) !important;
}

#footer .footer p{
    font-size: calc(14px + (16 - 14) * ((100vw - 360px) / (1600 - 360))) !important;
    color:rgb(78, 77, 77) !important;
}

#footer .footer .social{
    position: relative;
    left: -10px;
}

#footer .footer .Subscribe{
    background-color:#FFFFFF !important;
    font-weight: bold ;
}

#footer .footer small{
    color: #777 !important;
    margin-left: 5px;
}

#footer .footer .btn {
    border-radius: 50px;
}

#footer .footer .card-1{
    border-left: 3px solid #007bff !important;
    border-radius:0;
    
}

#footer .footer img{
     width: 50px !important;
 }

 #footer .footer hr.colored {
   
    height: 8px;
    background: linear-gradient(to left, rgba(196,222,138,1) 0%, rgba(196,222,138,1) 12.5%, rgba(245,253,212,1) 12.5%, rgba(245,253,212,1) 25%, rgba(255,208,132,1) 25%, rgba(255,208,132,1) 37.5%, rgba(242,122,107,1) 37.5%, rgba(242,122,107,1) 50%, rgba(223,157,185,1) 50%, rgba(223,157,185,1) 62.5%, rgba(192,156,221,1) 62.5%, rgba(192,156,221,1) 75%, rgba(95,156,217,1) 75%, rgba(95,156,217,1) 87.5%, rgba(94,190,227,1) 87.5%, rgba(94,190,227,1) 87.5%, rgba(94,190,227,1) 100%); /* W3C */
}

#footer .footer .fa {
    padding:calc(10px + (10 - 10) * ((100vw - 360px) / (1600 - 360))) !important;
    font-size: calc(15px + (20 - 15) * ((100vw - 360px) / (1600 - 360))) !important;
    width: calc(30px + (38 - 30) * ((100vw - 360px) / (1600 - 360))) !important;
    text-align: center;
    text-decoration: none;
    border-radius:50px !important;
    margin: 6px !important;
}

#footer .footer a{
    text-decoration: none !important;
}

#footer .footer .fa-facebook {
  background: #3B5998;
  color: white;
}
#footer .footer .fa:hover{
    background-color: transparent !important;
}
#footer .footer .fa-twitter {
  background: #55ACEE;
  color: white;
}

#footer .fa-instagram {
  background: #3f729b;
  color: white;
}

#footer .fa-linkedin {
  background: #0e76a8;
  color: white;
}

#footer .form-row{
    position: relative;
    left: calc(30px + (50 - 30) * ((100vw - 320px) / (1600 - 320))) ;
}

 @media screen and (max-width: 726px){
    #footer .form-row{
        position: relative;
        left: 0px !important;
    } 

}
@media screen and (max-width: 1143px){

    #footer .card-0{
        background: none;

    }
}
