#home-portal aside {
    color: #fff;
    width: 250px;
    padding-left: 20px;
    height: 100vh;
    background-image: linear-gradient(30deg , #0048bd, #44a7fd);
    border-top-right-radius: 80px;
  }
  #home-portal{
    padding-top: 2%;
    padding-bottom: 10%;
    padding-left: 0%!important;
  }
  #home-portal aside a {
    font-size: 12px;
    color: #fff;
    display: block;
    padding: 12px;
    padding-left: 30px;
    text-decoration: none;
    -webkit-tap-highlight-color:transparent;
  }
  
  #home-portal aside a:hover {
    color: #3f5efb;
    background: #fff;
    outline: none;
    position: relative;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  #home-portal aside a i {
    margin-right: 5px;
  }
  
  #home-portal aside a:hover::after {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 100%;
    right: 0;
    height: 35px;
    width: 35px;
    border-bottom-right-radius: 18px;
    box-shadow: 0 20px 0 0 #fff;
  }
  
  #home-portal aside a:hover::before {
    content: "";
    position: absolute;
    background-color: transparent;
    top: 38px;
    right: 0;
    height: 35px;
    width: 35px;
    border-top-right-radius: 18px;
    box-shadow: 0 -20px 0 0 #fff;
  }
  
  #home-portal  aside p {
    margin: 0;
    padding: 40px 0;
  }
  
  body {
   
    width: 100%;
    height: 100vh;
    margin: 0;
  }
  
  #home-portal .social {
    height: 0;  
  }
  
  #home-portal .social i:before {
    width: 14px;
    height: 14px;
    font-size: 14px;
    position: fixed;
    color: #fff;
    background: #0077B5;
    padding: 10px;
    border-radius: 50%;
    top:5px;
    right:5px;
  }

  

  #home-portal .card{
    width:250px;
    height:150px;
    border-radius:14px;
    padding:20px !important;
    margin: 20px;
    position:relative;
    
}

#home-portal .card .card-container{
    width:80%;
    height:70%;
    position:relative;
    background-color:#fff;
    z-index:10;
    border-radius:10px;
    padding:15px;
    padding-top:50px;
    
}

#home-portal .card-container h4{
    font-size:26px;
    margin-bottom:10px;
}

#home-portal .card p {
    font-size:17px;
}

#home-portal .card::before{
    position:absolute;
    content:'';
    background-color:#fc5185;
    height:35px;
    width:35px;
    top:3rem;
    right:-23px;
    transform:rotate(45deg);
    z-index:10;
    
}


#home-portal .card::after{
    position:absolute;
    content:attr(data-label);
    top:27px;
    padding-left:20px;
    padding:10px;
    right:-31px;
    width:8rem;
    background-color:#fc5185;
    z-index:12;
    border-bottom-left-radius:20px;
    border-top-left-radius:20px;
    border-top-right-radius:10px;
    color:#fff;
}